import {
  parse,
  format,
  startOfDay,
  isSameDay,
  isAfter,
  isBefore,
  fromUnixTime,
  addDays,
  endOfWeek,
  differenceInDays,
  subDays,
  differenceInHours,
} from 'date-fns';

export const parseEstimateDuration = (time) =>
  parse(time, 'HH:mm:ssX', new Date());

export const displayTime = (time) => format(time, 'p');

export const today = startOfDay(new Date());

export const thisWeek = endOfWeek(new Date());

export const thirtyDaysAgo = subDays(new Date(), 30);

export const displayDate = (d) => format(d, 'P');

export const newDbDate = 'yyyy-MM-dd';

export const displayDbDate = (d) => format(d, newDbDate);

export const todayDbDate = format(startOfDay(new Date()), newDbDate);

export const dateFromUnix = (unix) => fromUnixTime(unix);

export const dateFromDB = (db) => parse(db, newDbDate, new Date());

export const dateIsRecent = (d) => isAfter(d, today);

export const dbDateFromPreferredServiceDate = (preferredServiceDate) =>
  preferredServiceDate ? preferredServiceDate.substr(0, 10) : null;

export const displayPreferredServiceDate = (preferredServiceDate) =>
  displayDate(
    parse(
      dbDateFromPreferredServiceDate(preferredServiceDate),
      newDbDate,
      new Date(),
    ),
  );

export const calculateTodoDateFromOffset = (moveDate, dateOffset) =>
  addDays(dateFromDB(moveDate), dateOffset);

export const bookingDate = (db) => {
  const date = dateFromDB(db);
  return {
    day: format(date, 'EEE'),
    fullday: format(date, 'EEEE'),
    month: format(date, 'MMMM'),
    monthday: format(date, 'do'),
    date: format(date, 'M/d'),
  };
};

export const convertDateToOffset = (date, moveDate) =>
  differenceInDays(
    parse(date, newDbDate, new Date()),
    parse(moveDate, newDbDate, new Date()),
  );

export { isBefore, isAfter, isSameDay, differenceInHours };
