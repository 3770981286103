const styles = (theme) => ({
  // Hack to override global body font. See https://github.com/mui-org/material-ui/issues/9988#issuecomment-426631645
  '@global': {
    body: {
      fontFamily: theme.typography.fontFamily,
    },
  },
  skipLink: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    overflow: 'hidden',
    position: 'absolute',
    '&:focus': {
      padding: '1.75rem',
      width: '100%',
      textAlign: 'center',
      position: 'fixed',
      top: '10px',
      left: '10px',
      borderRadius: 3,
      background: '#15E8BF',
      fontWeight: 600,
      textDecoration: 'none',
      color: '#37344C',
      zIndex: 9999,
      height: 'auto',
      clip: 'auto',
      outline: 'none',
      boxShadow: '0px 4px 4px rgba(55, 52, 76, 0.15)',
      [theme.breakpoints.up('md')]: {
        maxWidth: 300,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 20px)',
      },
    },
  },
});

export default styles;
