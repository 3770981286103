import React from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

import { CONSENT_COOKIE_NAME, CONSENT_COOKIE } from 'constants/CookieInfo';

const defaultState = {
  cookieConsent: false,
  cookieConsentOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleCookieConsent: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleCookieConsentOpen: () => {},
};

const CookieContext = React.createContext(defaultState);

class CookieProviderComponent extends React.Component {
  state = {
    cookieConsent: false,
    cookieConsentOpen: false,
  };

  toggleCookieConsent = (consent) => {
    const cookieConsent = !this.state.cookieConsent;
    this.props.cookies.set(
      CONSENT_COOKIE_NAME,
      consent ? CONSENT_COOKIE.allow : CONSENT_COOKIE.deny,
      {
        maxAge: 2592000,
        domain: `.${window.location.hostname.replace(
          /^.*\.([^.]+\.[^.]+)$/,
          '$1',
        )}`,
      },
    );
    // eslint-disable-next-line no-unused-expressions
    typeof window !== 'undefined' &&
      window.dataLayer &&
      window.dataLayer.push({
        event: 'gdpr',
        consent,
      });
    this.setState({ ...this.state, cookieConsentOpen: false, cookieConsent });
  };

  toggleCookieConsentOpen = () => {
    this.setState({
      ...this.state,
      cookieConsentOpen: !this.state.cookieConsentOpen,
    });
  };

  componentDidMount() {
    const consent = this.props.cookies.get(CONSENT_COOKIE_NAME);
    const cookieConsentOpen = consent === undefined;
    this.setState({
      ...this.state,
      cookieConsent: consent === 'allow',
      cookieConsentOpen,
    });
  }

  render() {
    const { children } = this.props;
    const { cookieConsent, cookieConsentOpen } = this.state;
    return (
      <CookieContext.Provider
        value={{
          cookieConsent,
          cookieConsentOpen,
          toggleCookieConsent: this.toggleCookieConsent,
          toggleCookieConsentOpen: this.toggleCookieConsentOpen,
        }}
      >
        {children}
      </CookieContext.Provider>
    );
  }
}

CookieContext.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
};

export default CookieContext;

export const CookieProvider = withCookies(CookieProviderComponent);
