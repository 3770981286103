import LogRocket from 'logrocket';
import debug from 'debug';
// Local storage:
// key: debug
// value: tmt-customer-portal:*

const BASE = 'tmt-customer-portal';
const COLOURS: { [char: string]: string } = {
  trace: 'lightblue',
  info: 'blue',
  warn: 'pink',
  error: 'red',
};

class Logger {
  generateMessage(level: string, message: string, source?: any) {
    // Set the prefix which will cause debug to enable the message
    const namespace = `${BASE}:${level}`;
    const createDebug = debug(namespace);

    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level];

    if (source) {
      createDebug(source, message);
    } else {
      createDebug(message);
    }
  }

  trace(message: string, source?: any) {
    LogRocket.debug(message, source);
    return this.generateMessage('trace', message, source);
  }

  info(message: string, source?: any) {
    LogRocket.info(message, source);
    return this.generateMessage('info', message, source);
  }

  warn(message: string, source?: any) {
    LogRocket.warn(message, source);
    return this.generateMessage('warn', message, source);
  }

  error(message: string, source?: any) {
    LogRocket.captureException(new Error(message));
    return this.generateMessage('error', message, source);
  }
}

export default new Logger();
