import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
// import { themeDark } from './themeDark';

export const headerOffset = 40;
export const drawerWidth = 240;
export const drawerClosedWidth = 60;
export const mobileNavHeight = 80;
export const unit = 10;
export const primaryColor = '#15E8BF';
export const secondaryColor = '#E9005F';
export const tertiaryColor = '#ffa800';
export const darkColor = '#37344C';
export const blackColor = '#222222';
export const grayColor = '#D3D3DD';
export const darkGrayColor = '#64646D';
export const whiteTint = 'rgba(255,255,255,0.08)';
export const whiteTintStrong = 'rgba(255,255,255,0.5)';
export const primaryTint = '#e7fcf8';
export const secondaryTint = 'rgba(233,0,95, 0.1)';
export const primaryOpacity = 'rgba(21, 232, 191, 0.1)';

export const radius = 3;

export const orangePinkGradient =
  'linear-gradient(135deg, #ffa800 0%, #e9005f 100%)';
export const pinkOrangeGradient =
  'linear-gradient(135deg, #e9005f 0%, #ffa800 100%)';
export const transitionGradient =
  'linear-gradient(135deg, #ffa800 0%, #e9005f 50%, #37344C 100%)';
export const greenPinkGradient =
  'linear-gradient(90deg, #15E8BF 0%, #E9005F 100%)';
export const verticalGreenPinkGradient =
  'linear-gradient(0deg, #E9005F 0%, #15E8BF 100%)';

export const transformTransition =
  'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms';

export const theme = responsiveFontSizes(
  createMuiTheme({
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1520,
      },
    },
    palette: {
      common: {
        black: blackColor,
        white: '#ffffff',
        lightBlack: darkColor,
      },
      primary: {
        main: primaryColor,
        contrastText: darkColor,
        dark: darkColor,
      },
      secondary: {
        main: secondaryColor,
        light: '#C4F9EF',
        contrastText: '#ffffff',
      },
      tertiary: {
        main: tertiaryColor,
        contrastText: darkColor,
      },
      error: {
        main: secondaryColor,
        contrastText: '#ffffff',
      },
      text: {
        primary: darkColor,
        secondary: darkGrayColor,
        disabled: grayColor,
        hint: grayColor,
      },
      divider: grayColor,
      background: {
        paper: '#ffffff',
        default: '#F7F7F9',
      },
    },
    typography: {
      fontFamily: '"Barlow", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 16,
      button: {
        fontSize: '1rem',
        color: '#37344C',
      },
      h1: {
        fontSize: '3.5rem',
        fontWeight: 700,
      },
      h2: {
        fontSize: '1.875rem',
        fontWeight: 700,
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: 700,
      },
      h4: {
        fontSize: '1.35rem',
        fontWeight: 700,
      },
      h5: {
        fontSize: '1.125rem',
        fontWeight: 700,
      },
    },
    // spacing: {
    //   unit,
    // },
    spacing: unit,
    overrides: {
      // Alert
      MuiAlert: {
        root: {
          borderColor: grayColor,
          borderWidth: 1,
          borderStyle: 'solid',
        },
      },
      // Chip
      // MuiChip: {
      //   root: {
      //     fontSize: '0.9rem',
      //     fontWeight: 600,
      //   },
      // },
      // Button
      MuiButton: {
        root: {
          // padding: '10px 20px',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'none',
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
            '&$disabled': {
              backgroundColor: 'transparent',
            },
          },
        },
        text: {
          textTransform: 'none',
          // padding: '10px 0px',
          borderRadius: 0,
          '&:after': {
            borderBottom: `1px solid`,
            left: 0,
            bottom: 0,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '""',
            position: 'absolute',
            right: 0,
            transform: 'scaleX(0)',
            transition: transformTransition,
          },
          '&:hover,&:focus': {
            backgroundColor: 'transparent',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
            '&:after': {
              transform: 'scaleX(1)',
            },
          },
        },
        /* Styles applied to the root element if `variant="text"` and `color="primary"`. */
        textPrimary: {
          '&:after': {
            borderColor: primaryColor,
          },
          '&:hover': {
            backgroundColor: 'none',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        /* Styles applied to the root element if `variant="text"` and `color="secondary"`. */
        textSecondary: {
          '&:after': {
            borderColor: secondaryColor,
          },
          // color: theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: 'none',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        /* Styles applied to the root element if `variant="outlined"`. */
        outlined: {
          textTransform: 'none',
          border: '1px solid',
          '&:after': {
            borderBottom: `1px solid`,
            left: 0,
            bottom: 0,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '""',
            position: 'absolute',
            right: 0,
            transform: 'scaleX(0)',
            transition: transformTransition,
          },
          '&:hover,&:focus': {
            border: '1px solid',
            '&:after': {
              transform: 'scaleX(1)',
            },
          },
        },
        /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
        outlinedPrimary: {
          border: '1px solid',
          borderColor: primaryColor,
          color: darkColor,
          '&:hover,&:focus': {
            border: '1px solid',
            borderColor: primaryColor,
          },
          '&:after': {
            borderColor: primaryColor,
          },
        },
        /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
        outlinedSecondary: {
          border: '1px solid',
          '&:hover,&:focus': {
            border: '1px solid',
          },
          '&:after': {
            borderColor: secondaryColor,
          },
        },
        /* Styles applied to the root element if `variant="[contained | fab]"`. */
        contained: {
          boxShadow: 'none',
          textTransform: 'none',
          fontWeight: 600,
          '&:after': {
            borderBottom: `1px solid`,
            left: 0,
            bottom: 0,
            // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
            content: '""',
            position: 'absolute',
            right: 0,
            transform: 'scaleX(0)',
            transition: transformTransition,
            borderRadius: '0px 0px 3px 3px',
          },
          '&:hover,&:focus': {
            backgroundColor: 'transparent',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
            '&:after': {
              transform: 'scaleX(1)',
            },
          },
          '&$disabled': {},
        },
        /* Styles applied to the root element if `variant="[contained | fab]"` and `color="primary"`. */
        containedPrimary: {
          '&:hover,&:focus': {
            backgroundColor: '#50eecf',
          },
          '&$disabled': {
            // color: theme.palette.primary.disabled,
            // boxShadow: theme.shadows[0],
            backgroundColor: primaryColor,
          },
        },
        /* Styles applied to the root element if `variant="[contained | fab]"` and `color="secondary"`. */
        containedSecondary: {
          '&:hover,&:focus': {
            backgroundColor: '#ef4087',
          },
        },
        /* Styles applied to the ButtonBase root element if the button is keyboard focused. */
        focusVisible: {},
        /* Styles applied to the root element if `disabled={true}`. */
        disabled: {},
        /* Styles applied to the root element if `color="inherit"`. */
        colorInherit: {
          color: 'inherit',
        },
        /* Styles applied to the root element if `size="small"`. */
        sizeSmall: {
          // padding: '8px 16px',
          // minWidth: 64,
          // minHeight: 32,
          // fontSize: theme.typography.pxToRem(13),
        },
        /* Styles applied to the root element if `size="large"`. */
        sizeLarge: {
          // padding: '15px 40px',
          // minWidth: 112,
          // minHeight: 40,
          // fontSize: theme.typography.pxToRem(15),
        },
        /* Styles applied to the root element if `fullWidth={true}`. */
        fullWidth: {
          width: '100%',
        },
      },
      // Paper
      MuiPaper: {
        elevation1: {
          boxShadow: 0,
          borderColor: grayColor,
          borderWidth: 1,
          borderStyle: 'solid',
        },
        rounded: {
          borderRadius: radius,
        },
      },
      // Snackbar
      MuiSnackbar: {
        anchorOriginBottomCenter: {
          bottom: 20,
        },
      },
      // Snackbar Content
      MuiSnackbarContent: {
        root: {
          backgroundColor: darkColor,
          borderRadius: radius,
          position: 'relative',
          display: 'block',
          width: '100%',
          fontWeight: 500,
          padding: '12px 20px 10px',
        },
        message: {
          display: 'flex',
        },
      },
      // Accordion
      // MuiAccordion: {
      //   root: {
      //     borderRadius: radius,
      //     marginTop: -1,
      //     '&:first-child': {
      //       borderRadius: `${radius}px ${radius}px 0 0`,
      //       borderTopLeftRadius: radius,
      //       borderTopRightRadius: radius,
      //     },
      //     '&:last-child': {
      //       borderRadius: `0 0 ${radius}px ${radius}px`,
      //       borderBottomLeftRadius: radius,
      //       borderBottomRightRadius: radius,
      //     },
      //     '&$expanded': {
      //       borderRadius: `${radius}px !important`,
      //     },
      //   },
      // },
      MuiAccordion: {
        root: {
          '&$expanded': {
            margin: 'auto',
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          '&$expanded': {
            minHeight: 'auto',
          },
          '&$focused': {
            backgroundColor: darkColor,
            color: 'white',
            '& svg': {
              color: 'white',
            },
          },
        },
        content: {
          '&$expanded': {
            margin: 'auto',
          },
        },
      },
      // // Accordion Details
      // MuiAccordionDetails: {
      //   root: {
      //     display: 'block',
      //     borderTop: '1px solid',
      //     borderColor: grayColor,
      //   },
      // },
      // Input Label
      MuiInputLabel: {
        root: {
          color: darkGrayColor,
        },
        // You are trying to override a style that does not exist.
        // Fix the `&$focused` key of `theme.overrides.MuiInputLabel`.
        // '&$focused': {
        //   color: darkColor,
        // },
      },
      // Input Label
      MuiFormLabel: {
        root: {
          color: darkGrayColor,
          '&$focused': {
            color: darkColor,
          },
        },
      },
      // Input
      MuiInput: {
        root: {
          '@media (max-width: 599.95px)': {
            fontSize: 16,
          },
          '&$focused': {
            background: primaryOpacity,
            color: darkColor,
          },
          '&$underline:after': {
            borderBottomColor: darkColor,
          },
        },
        underline: {
          borderColor: 'green',
        },
        focused: {},
        input: {
          '&::-ms-clear': {
            display: 'none',
          },
        },
      },
      // Dialog
      // MuiDialog: {
      //   paper: {
      //     margin: 20,
      //     width: '100%',
      //     maxWidth: 600,
      //   },
      //   scrollPaper: {
      //     '@media (max-width: 599.95px)': {
      //       alignItems: 'flex-end',
      //     },
      //   },
      //   paperScrollPaper: {
      //     '@media (max-width: 599.95px)': {
      //       height: 'calc(100% - 20px)',
      //       marginBottom: 0,
      //       maxHeight: 'none',
      //       borderRadius: '3px 3px 0 0',
      //     },
      //   },
      // },
      // Dialog Title
      MuiDialogTitle: {
        root: {
          position: 'relative',
          paddingTop: unit * 2,
          paddingLeft: unit * 2.5,
          paddingRight: unit * 6,
          paddingBottom: 0,
          zIndex: 1,
        },
      },
      // Dialog Content
      MuiDialogContent: {
        root: {
          padding: '20px 25px',
        },
      },
      // Dialog Actions
      MuiDialogActions: {
        root: {
          margin: 0,
          paddingBottom: unit * 2.5,
          paddingLeft: unit * 2.5,
          paddingRight: unit * 2.5,
          zIndex: 1,
          justifyContent: 'flex-end',
          '@media (max-width: 599.95px)': {
            justifyContent: 'center',
          },
        },
        // Material-UI: you are trying to override a style that does not exist. Fix the `action` key of `theme.overrides.MuiDialogActions`.
        // action: {
        //   '@media (max-width: 599.95px)': {
        //     flex: '1 0 auto',
        //   },
        // },
      },
      // Tooltip
      MuiTooltip: {
        tooltip: {
          backgroundColor: darkColor,
          color: 'white',
        },
        arrow: {
          color: darkColor,
        },
      },
      // Slider
      MuiSlider: {
        root: {
          color: primaryColor,
          padding: '21px 0',
        },
        rail: {
          backgroundColor: grayColor,
          height: 2,
          borderTop: '3px solid #F7F7F9',
          borderBottom: '3px solid #F7F7F9',
          borderRadius: '8px',
          opacity: 1,
        },
        track: {
          backgroundColor: primaryColor,
          height: 8,
          borderRadius: '8px 0 0 8px',
        },
        valueLabel: {
          fontWeight: 700,
          position: 'relative',
          top: '12px',
          left: 'calc(-50% + 5px)',
        },
      },
      // Typography
      MuiTypography: {
        gutterBottom: {
          marginBottom: 20,
        },
      },
      // Step Icon
      MuiStepIcon: {
        root: {
          border: '2px solid',
          background: '#F7F7F9',
          color: '#F7F7F9',
          borderColor: grayColor,
          borderRadius: '50%',
        },
        completed: {
          background:
            'radial-gradient(closest-side, #37344C 0%, #37344C 92%, #15E8BF 93%, #15E8BF 100%)',
          borderRadius: '50%',
          border: 0,
        },
        text: {
          display: 'none',
        },
        active: {
          border: '6px solid',
          borderColor: secondaryColor,
          '&$root': {
            color: '#F7F7F9',
          },
        },
      },
      //Step Connector
      MuiStepConnector: {
        root: {
          zIndex: -1,
        },
        line: {
          border: 0,
          borderColor: 'transparent',
          background: grayColor,
        },
        lineHorizontal: {
          height: 2,
          '&$active': {
            height: 6,
          },
        },
        lineVertical: {
          width: 2,
          borderWidth: 0,
          marginLeft: 3,
          padding: 0,
          marginBottom: -8,
          minHeight: 12,
        },
        alternativeLabel: {
          left: 'calc(-50% + 18px)',
          right: 'calc(50% + 18px)',
          top: 20,
        },
        completed: {
          '& $lineHorizontal': {
            height: 6,
            position: 'relative',
            top: -2,
            background: primaryColor,
          },
          '& $lineVertical': {
            width: 6,
            background: primaryColor,
            position: 'relative',
            left: -2,
          },
        },
        active: {
          '& $lineHorizontal': {
            height: 6,
            background: greenPinkGradient,
            position: 'relative',
            top: -2,
          },
          '& $lineVertical': {
            width: 6,
            background: verticalGreenPinkGradient,
            position: 'relative',
            left: -2,
          },
        },
      },
    },
  }),
);

export const onboardTheme = responsiveFontSizes(
  createMuiTheme({
    ...theme,
    palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        default: darkColor,
      },
    },
  }),
);

export const themeForOnboardingState = (onboardingState) =>
  onboardingState === undefined || onboardingState !== false
    ? onboardTheme
    : theme;

export default theme;
