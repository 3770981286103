import React from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';

import styles from './Footer.styles';
import LanguageSwitcher from 'components/LanguageSwitcher';
// import visuallyHidden from 'theme/styles/visuallyHidden';

const useStyles = makeStyles((theme) => styles(theme));

const Footer = ({ background, sidebarOpen = false, hasSidebar = true }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <footer
      role="contentinfo"
      id="footer"
      className={clsx({
        [classes.footer]: background !== 'dark',
        [classes.footerSidebarOpen]: sidebarOpen,
        [classes.footerSidebarClosed]: !sidebarOpen,
        [classes.footerNoSidebar]: !hasSidebar,
      })}
    >
      <div
        className={clsx(classes.container, {
          [classes.dashContainer]: background !== 'dark',
        })}
      >
        <Link href="https://movetrac.com/" className={classes.imgLink}>
          <img
            // TODO: Get conditional light/dark versions of logo
            src={
              background === 'dark'
                ? '/img/movetrac-logo-light.svg'
                : '/img/movetrac-logo.svg'
            }
            // src="./img/movetrac-logo.svg"
            alt={t(`movetrac`)}
            aria-label={t(`Move Track`)}
            className={classes.logo}
          />
        </Link>
        <div
          className={clsx(classes.footerText, {
            [classes.footerTextDarkBg]: background === 'dark',
          })}
        >
          <Grid container className={classes.copyrightContainer}>
            <LanguageSwitcher background={background} />
            <p style={{ margin: 0, marginLeft: '1em' }}>
              &copy; {new Date().getFullYear()} TWO MEN AND A TRUCK
            </p>
          </Grid>
          <Typography className={classes.buttonGroup} style={{ marginTop: 5 }}>
            <Link
              href="https://www.servicemaster.com/privacy-policy/"
              className={clsx(classes.smallFooterButton, {
                [classes.smallFooterButtonDarkBg]: background === 'dark',
              })}
              target="_blank"
            >
              <Trans>
                Privacy Policy
                <span className={classes.visuallyHidden}>
                  {' '}
                  (opens in a new window)
                </span>
              </Trans>
              <FontAwesomeIcon
                icon={faExternalLink}
                style={{ marginLeft: 4, fontSize: '0.75em' }}
              />
            </Link>
            <Link
              href="https://www.servicemaster.com/accessibility-statement/"
              className={clsx(classes.smallFooterButton, {
                [classes.smallFooterButtonDarkBg]: background === 'dark',
              })}
              target="_blank"
            >
              <Trans>
                Accessibility Statement
                <span className={classes.visuallyHidden}>
                  {' '}
                  (opens in a new window)
                </span>
              </Trans>
              <FontAwesomeIcon
                icon={faExternalLink}
                style={{ marginLeft: 4, fontSize: '0.75em' }}
              />
            </Link>
            <Link
              href="https://movetrac.com/terms/"
              className={clsx(classes.smallFooterButton, {
                [classes.smallFooterButtonDarkBg]: background === 'dark',
              })}
            >
              <Trans>Terms of Use</Trans>
            </Link>
            <Link
              href="https://www.servicemaster.com/do-not-sell-share-my-info/"
              className={clsx(classes.smallFooterButton, {
                [classes.smallFooterButtonDarkBg]: background === 'dark',
              })}
              target="_blank"
            >
              <Trans>
                Do Not Sell/Share My Info
                <span className={classes.visuallyHidden}>
                  {' '}
                  (opens in a new window)
                </span>
              </Trans>
              <FontAwesomeIcon
                icon={faExternalLink}
                style={{ marginLeft: 4, fontSize: '0.75em' }}
              />
            </Link>
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
