import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import { isProd } from 'lib/utils';

import i18nConfig from './i18n-config.mjs';

const {
  backendCloudFrontDomain,
  backendDomain,
  app,
  authorization,
} = i18nConfig;

const backend = {
  // path where resources get loaded from, or a function
  // returning a path:
  // function(lngs, namespaces) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  // the function might return a promise
  // returning falsy will abort the download
  //
  // If allowMultiLoading is false, lngs and namespaces will have only one element each,
  // If allowMultiLoading is true, lngs and namespaces can have multiple elements
  loadPath: `https://${
    isProd ? backendCloudFrontDomain : backendDomain
  }/translations/get/${app}/{{lng}}`,

  // path to post missing resources, or a function
  // function(lng, namespace) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  // addPath: '/locales/add/{{lng}}/{{ns}}',
  addPath: `https://${backendDomain}/translations/update/${app}`,
  crossDomain: true,
  withCredentials: !isProd,
  overrideMimeType: true,
  // custom request headers sets request.setRequestHeader(key, value)
  customHeaders: {
    ...(!isProd && {
      authorization,
    }),
    // 'Content-Type': 'application/json',
    // ...
  },
  // used for fetch, can also be a function (payload) => ({ method: 'GET' })
  requestOptions: {
    mode: 'cors',
    ...(!isProd && {
      credentials: 'include',
    }),
    // cache: 'default'
  },
};

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !isProd,
    fallbackLng: 'en',
    // saveMissing: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend,
    react: {
      // bindI18n: 'languageChanged',
      // bindI18nStore: '',
      // transEmptyNodeValue: '',
      // transSupportBasicHtmlNodes: true,
      // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      // useSuspense: true,
    },
  });

export default i18n;
