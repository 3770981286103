import React from 'react';
import { useNavigate, useLocation } from '@reach/router';

const ScrollToTop = (props) => {
  const navigate = useNavigate();
  const { href, state } = useLocation();

  const updateState = React.useCallback(() => {
    navigate(href, {
      state: { ...(typeof state === 'object' ? state : {}), scrolled: true },
      replace: true,
    }).then(() => window?.scrollTo(0, 0));
  }, [href, state, navigate]);

  // Mark the page as scrolled on first mount
  React.useEffect(updateState, []);

  React.useLayoutEffect(() => {
    if (!state?.scrolled) {
      updateState();
    }
  }, [state, updateState]);

  return <>{props.children}</>;
};

export default ScrollToTop;
