import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';

import Loading from './Loading';
import Footer from '../Footer/Footer';

import paddedCardInner from '../../theme/styles/paddedCardInner';

const styles = (theme) => ({
  ...paddedCardInner,
  onboarding: {
    position: 'relative',
    minHeight: '100vh',
  },
  backgroundBorder: {
    position: 'absolute',
    left: 0,
    width: '100%',
    maxWidth: '100%',
    pointerEvents: 'none',
  },
  borderTop: {
    top: 0,
  },
  borderBottom: {
    bottom: 0,
  },
  gridItem: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2.5),
    zIndex: 2,
  },
});

const useStyles = makeStyles((theme) => styles(theme));

const LoadingCard = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
      className={classes.onboarding}
    >
      <img
        src="./img/border-top.svg"
        alt=""
        role="presentation"
        className={clsx(classes.backgroundBorder, classes.borderTop)}
      />
      <Grid
        item
        lg={6}
        md={8}
        sm={10}
        classes={{
          item: classes.gridItem,
        }}
      >
        <Card className={classes.card}>
          <CardContent className={classes.paddedCardInner}>
            <Loading />
          </CardContent>
        </Card>
        <Footer background="dark" />
      </Grid>
      <img
        src="./img/border-bottom.svg"
        alt=""
        role="presentation"
        className={clsx(classes.backgroundBorder, classes.borderBottom)}
      />
    </Grid>
  );
};

export default LoadingCard;
