import { GetAccountEstimatesDocument } from 'graphql/compiled';
import logger from './logger';
import client from '../graphql/client';
import {
  isBefore,
  isAfter,
  dateFromDB,
  dbDateFromPreferredServiceDate,
  thirtyDaysAgo,
} from './date';

/**
 * @typedef {import("../graphql/compiled").EstimateFragmentFragment} EstimateFragmentFragment
 * @typedef {import("../graphql/compiled").EstimateBuilding} EstimateBuilding
 * @typedef {import("../graphql/compiled").Address} Address
 */

// eslint-disable-next-line import/prefer-default-export
export const getEstimates = async () => {
  logger.info('getEstimates');

  const response = await client.query({
    query: GetAccountEstimatesDocument,
    fetchPolicy: 'no-cache',
  });

  return response.data.getAccount.estimates;
};

/**
 * @param {EstimateFragmentFragment | null | undefined} estimate - An estimate.
 * @return {EstimateBuilding} The from building.
 */
export const estimateFromBuilding = (estimate) =>
  estimate &&
  estimate.inventory.buildings &&
  estimate.inventory.buildings.length &&
  estimate.inventory.buildings[0];

/**
 * @param {EstimateFragmentFragment | null | undefined} estimate - An estimate.
 * @return {Address} The from address.
 */
export const estimateFromAddress = (estimate) =>
  estimate &&
  estimate.addresses &&
  estimate.addresses.length &&
  estimate.addresses[0] &&
  estimate.addresses[0].street &&
  estimate.addresses[0];

/**
 * @param {EstimateFragmentFragment | null | undefined} estimate - An estimate.
 * @return {Address} The to address.
 */
export const estimateToAddress = (estimate) =>
  estimate &&
  estimate.addresses &&
  estimate.addresses.length &&
  estimate.addresses[estimate.addresses.length - 1] &&
  estimate.addresses[estimate.addresses.length - 1].street &&
  estimate.addresses[estimate.addresses.length - 1];

/**
 * @param {EstimateFragmentFragment | null | undefined} estimate - An estimate.
 * @return {EstimateBuilding} The to building.
 */
export const estimateToBuilding = (estimate) =>
  estimate &&
  estimate.inventory.buildings &&
  estimate.inventory.buildings.length &&
  estimate.inventory.buildings[estimate.inventory.buildings.length - 1];

// eslint-disable-next-line import/prefer-default-export
export const estimateIsOpen = ({ estimate }) =>
  ['Open', 'Revised'].includes(estimate.status);

export const estimateIsPending = ({ estimate, estimateAction }) =>
  estimate && ['book'].includes(estimateAction) && !estimate.isBooked;

export const estimateIsQuestion = ({ estimateAction }) =>
  ['question'].includes(estimateAction);

export const estimateIsCanceled = ({ estimate, estimateAction }) =>
  estimate &&
  (['cancel'].includes(estimateAction) ||
    ['No Sale'].includes(estimate.status));

export const estimateIsExpired = ({ estimate, estimateAction }) =>
  estimate &&
  !['question'].includes(estimateAction) &&
  isBefore(
    dateFromDB(dbDateFromPreferredServiceDate(estimate.preferredServiceDate)),
    new Date(),
  );

export const estimateBookingStatusDisplay = ({
  estimate,
  estimateAction,
  t,
}) => {
  if (estimateIsPending({ estimate, estimateAction })) {
    return t(`Pending`);
  }

  return estimate.isBooked ? t(`Booked`) : t(`Not Booked`);
};

// export const estimateStatusDisplay = ({
//   estimate,
//   estimateAction,
//   i18n,
//   startedLead,
// }) => {
//   if (estimate.id) {
//     if (estimateAction) {
//       if (estimateAction === 'book') {
//         return t(`Active`);
//       }

//       if (estimateAction === 'cancel') {
//         return t(`Canceled`);
//       }

//       return t(`Pending`);
//     }

//     if (estimateIsExpired({ estimate })) {
//       return t(`Expired`);
//     }

//     const options = {
//       Open: t(`Active`),
//       Revised: t(`Active`),
//       Booked: t(`Active`),
//       'No Sale': t(`Canceled`),
//     };

//     return options[estimate.status];
//   }

//   if (startedLead) {
//     return t(`In Progress`);
//   }

//   return t(`Not Started`);
// };

// export const estimateUseHourlyRate = () => false;

export const filterRecentEstimates = (estimates) =>
  estimates.filter((e) =>
    isAfter(
      dateFromDB(dbDateFromPreferredServiceDate(e.preferredServiceDate)),
      thirtyDaysAgo,
    ),
  );

export const filterRelevantEstimates = (estimates) =>
  filterRecentEstimates(estimates).filter((e) => e.status !== 'No Sale');

export const sortEstimatesByDate = (estimates) =>
  estimates.slice().sort((a, b) => {
    const dateA = new Date(a.preferredServiceDate);
    const dateB = new Date(b.preferredServiceDate);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });

export const getEstimateFromRegion = (estimate) =>
  estimate &&
  estimate.addresses &&
  estimate.addresses[0] &&
  estimate.addresses[0].region;
