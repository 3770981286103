import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Trans } from 'react-i18next';
import 'typeface-barlow-condensed';

const Loading = () => (
  <div
    style={{
      textAlign: 'center',
      padding: 20,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    <CircularProgress
      style={{ width: 100, height: 100, display: 'block', margin: '0 auto' }}
      thickness={1}
      color="inherit"
    />
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontFamily: 'barlow condensed',
        color: '#64646D',
        textTransform: 'uppercase',
        fontWeight: 300,
      }}
    >
      <Trans>Loading</Trans>
    </div>
  </div>
);

export default Loading;
