import smallButton from './smallButton';
import { theme } from '../theme';

export default {
  smallButtonInline: {
    ...smallButton.smallButton,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
      position: 'relative',
      // '&::before': {
      //   content: '""',
      //   position: 'absolute',
      //   left: -theme.spacing(1),
      //   top: 6,
      //   height: 12,
      //   width: 1,
      //   borderLeft: '1px solid',
      //   borderColor: '#D3D3DD',
      // },
    },
  },
};
