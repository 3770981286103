import React from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import smallButtonInline from 'theme/styles/smallButtonInline';

const useStyles = makeStyles((theme) => ({
  activeButton: {
    color: 'white',
  },
  visuallyHidden: {
    position: 'absolute !important',
    clip: 'rect(1px, 1px, 1px, 1px)',
    padding: '0 !important',
    border: '0 !important',
    height: '1px !important',
    width: '1px !important',
    overflow: 'hidden',
  },
  smallButtonInline: {
    ...smallButtonInline,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    paddingLeft: 0,
    // paddingRight: 0,
    minWidth: 'auto',
  },
}));

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const options = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
  ];

  console.log('i18n.resolvedLanguage', i18n.resolvedLanguage);

  return (
    <Box>
      <Typography className={classes.visuallyHidden} component="span">
        <Trans>Current Language</Trans>
      </Typography>
      {options.map((o) => (
        <Button
          key={o.value}
          size="small"
          variant="text"
          // color='inherit'
          onClick={() => {
            i18n.changeLanguage(o.value);
            window.location.reload();
          }}
          className={clsx(
            classes.smallButtonInline,
            o.value === i18n.resolvedLanguage
              ? classes.activeButton
              : undefined,
          )}
        >
          {o.label}
        </Button>
      ))}
    </Box>
  );
};

export default LanguageSwitcher;
