import { InMemoryCache } from '@apollo/client';

import { GetAppStateDocument } from 'graphql/compiled';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getToDo(_, { args, toReference }) {
          return toReference({
            __typename: 'ToDo',
            id: args.id,
          });
        },
        getDocumentByEstimateId(_, { args, toReference }) {
          return toReference({
            __typename: 'Document',
            id: args.documentID,
          });
        },
      },
    },
    Move: {
      fields: {
        todos: {
          merge(_ignored, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

const appState = {
  __typename: 'AppState',
  startedLead: false,
  sidebarOpen: true,
  estimateActions: [],
  snackBar: {
    open: false,
    variant: 'info',
    message: null,
  },
  ...(window.localStorage.getItem('appState')
    ? JSON.parse(window.localStorage.getItem('appState'))
    : {}),
};

window.localStorage.setItem('appState', JSON.stringify(appState));

// Set app state default.
cache.writeQuery({
  query: GetAppStateDocument,
  data: {
    appState,
  },
});

export default cache;
