// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

import 'intersection-observer';

// Necessary for yup.
// @TODO: Remove?
// import 'core-js/stable/promise';
// import 'core-js/stable/set';
// import 'core-js/stable/map';

// Necessary for IE11.
// import 'core-js/stable/function';
// import 'core-js/stable/array';
// import 'core-js/stable/object';

import React from 'react';
import ReactDOM from 'react-dom';

import 'typeface-barlow';
import 'typeface-barlow-condensed';

import LogRocket from 'logrocket';
import App from './App';
// import * as serviceWorker from './serviceWorker';

if (import.meta.env.NODE_ENV === 'development') {
  LogRocket.init('vgya4p/tmt-customer-portal-d');
  // const whyDidYouRender = require('@welldone-software/why-did-you-render');
  // whyDidYouRender(React);
} else {
  LogRocket.init('5ph1bb/movetrac');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

// if (import.meta.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const a11y = require('react-a11y').default;
//   a11y(React, ReactDOM, {
//     rules: {
//       'img-uses-alt': 'warn',
//       'img-redundant-alt': ['warn', ['image', 'photo', 'foto', 'bild']],
//     },
//   });
// }

ReactDOM.render(<App />, document.getElementById('root'));
