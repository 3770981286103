import { GetAppStateDocument } from 'graphql/compiled';

const resolvers = {
  Mutation: {
    updateAppState: (_, state, { cache }) => {
      const { appState } = cache.readQuery({
        query: GetAppStateDocument,
      });

      const newState = {
        ...appState,
        ...state.input,
      };

      window.localStorage.setItem('appState', JSON.stringify(newState));

      cache.writeQuery({
        query: GetAppStateDocument,
        data: {
          appState: newState,
        },
      });
      return newState;
    },
  },
};

export default resolvers;
