import { theme, primaryTint, grayColor, radius } from '../theme';

export default {
  fancySelectLabel: {
    width: '100%',
    position: 'relative',
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(1),
    background: '#F7F7F9',
    transition: 'all 0.2s ease',
    // label: {
    //   width: '100%',
    // },
    '&:hover': {
      background: 'white',
    },
  },
  fancySelect: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    color: '#F7F7F9',
    borderRadius: radius,
    border: '1px solid',
    borderColor: grayColor,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    fontSize: '1rem',
    transition: 'all 0.2s ease',
    padding: theme.spacing(2),
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: 'white',
      boxShadow: 'inset 0 0 4px #15E8BF',
    },
  },
  fancySelectFocused: {
    transition: 'all 0.2s ease',
    color: primaryTint,
    borderColor: theme.palette.primary.main,
    boxShadow: 'inset 0 0 4px #15E8BF',
    background: 'white',
  },
  fancySelectChecked: {
    background: primaryTint,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      background: 'white',
    },
  },
  innerLabel: {
    width: '100%',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2),
    zIndex: 2,
    pointerEvents: 'none',
  },
  fancyIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    fontSize: '1.1rem',
    width: '18px !important',
  },
  selectHeader: {
    fontWeight: 600,
    paddingRight: theme.spacing(1),
    lineHeight: '1.3',
  },
  selectDescription: {
    color: theme.palette.text.primary,
  },
};
