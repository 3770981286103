import smallButtonInline from '../../theme/styles/smallButtonInline';
import { drawerWidth, mobileNavHeight, grayColor } from 'theme/theme';

const styles = (theme) => ({
  footer: {
    flexGrow: 1,
    position: 'relative',
    width: '100%',
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: mobileNavHeight,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  footerSidebarOpen: {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
  },
  footerSidebarClosed: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 60,
    },
  },
  imgLink: {
    transition: 'all 0.2s ease',
    borderBottom: '1px solid transparent',
    '&:hover, &:focus': {
      borderColor: '#37344C',
      outline: 'none',
    },
  },
  container: {
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  },
  dashContainer: {
    borderTop: '1px solid',
    borderColor: grayColor,
    margin: '0 auto',
    maxWidth: 'calc(100% - 80px)',

    [theme.breakpoints.up('lg')]: {
      maxWidth: 1120,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  },
  logo: {
    width: 130,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 8,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  footerText: {
    fontSize: '0.8rem',
    color: '#64646D',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  smallFooterButton: {
    ...smallButtonInline.smallButtonInline,
    color: '#64646D',
    display: 'inline-block',
    '&:focus': {
      outline: 'none',
      textDecoration: 'underline',
    },
  },
  footerTextDarkBg: {
    color: '#D3D3DD',
  },
  smallFooterButtonDarkBg: {
    color: '#D3D3DD',
    fontWeight: 400,
  },
  footerNoSidebar: {
    marginLeft: '0',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  copyrightContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
});

export default styles;
