import React, { Suspense } from 'react';
import Amplify from 'aws-amplify';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider } from 'react-i18next';

import logger from 'lib/logger.ts';
import i18n from './i18n';
import { gtmLoad } from './lib/utils';
import AppContainer from './components/App/AppContainer';
import ErrorCard from './components/Error/ErrorCard';

Amplify.configure({
  Auth: {
    region: import.meta.env.REACT_APP_AUTH_REGION,
    userPoolId: import.meta.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);

    this.state = {
      authState: props.authState || null,
      authData: props.authData || null,
    };
  }

  componentDidMount() {
    gtmLoad();
  }

  handleAuthStateChange(state, data) {
    this.setState({ authState: state, authData: data });
  }

  render() {
    const { authState } = this.state;
    logger.info(authState, 'authState');
    return (
      <Suspense fallback="Loading">
        <I18nextProvider i18n={i18n}>
          <ErrorBoundary FallbackComponent={ErrorCard}>
            <AppContainer
              authState={authState}
              handleAuthStateChange={this.handleAuthStateChange}
            />
          </ErrorBoundary>
        </I18nextProvider>
      </Suspense>
    );
  }
}

export default App;
