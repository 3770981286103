/* eslint-disable no-unused-expressions */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { grayColor, primaryColor, blackColor } from 'theme/theme';
import CookieContext from 'contexts/CookieContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';

const styles = (theme) => ({
  cookieBanner: {
    backgroundColor: theme.palette.common.lightBlack,
    position: 'fixed',
    bottom: -120,
    left: 0,
    right: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    textAlign: 'center',
    zIndex: -1,
    opacity: 0,
    transition: 'all 0.3s ease',

    '.show-prompt &': {
      opacity: 1,
      zIndex: 10000,
      bottom: 0,
    },
  },
  revokeButton: {
    position: 'fixed',
    bottom: -21,
    left: 0,
    zIndex: 10,
    fontSize: '0.85em',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    transition: 'all 0.3s ease',

    '&:hover,&:focus': {
      bottom: 0,
    },
  },
  consentLink: {
    color: '#fff',
    textDecoration: 'underline',
  },
  consentText: {
    color: '#fff',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),

    '@media (min-width: 768px)': {
      display: 'inline-block',
    },
  },
  consentButtons: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),

    '@media (min-width: 768px)': {
      display: 'inline-block',
    },
  },
  consentButton: {
    fontSize: '0.85em',
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    color: blackColor,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',

    '&:after': {
      borderColor: '#fff',
    },

    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      borderColor: '#fff',
      color: '#fff',
    },
  },
  allowButton: {
    backgroundColor: primaryColor,
  },
  denyButton: {
    backgroundColor: grayColor,
  },
});

const CookieConsent = ({ classes }) => (
  <CookieContext.Consumer>
    {(cookieContext) => (
      <div
        className={clsx(
          classes.root,
          cookieContext.cookieConsentOpen ? 'show-prompt' : '',
        )}
      >
        <Button
          className={classes.revokeButton}
          variant="contained"
          tabIndex={cookieContext.cookieConsentOpen ? '-1' : '0'}
          onClick={() => cookieContext.toggleCookieConsentOpen(true)}
        >
          Cookie Settings
        </Button>
        <div className={classes.cookieBanner}>
          <div className={classes.consentText}>
            <Trans>
              We use cookies to ensure you get the best experience on our
              website.
            </Trans>{' '}
            <Trans>
              Learn more about our{' '}
              <Link
                href="https://www.servicemaster.com/privacy-policy/"
                target="_blank"
                className={classes.consentLink}
                aria-label="Privacy policy (opens in a new window)"
              >
                privacy policy
                <FontAwesomeIcon
                  icon={faExternalLink}
                  style={{ marginLeft: 4, fontSize: '0.75em' }}
                />
              </Link>{' '}
              and{' '}
              <Link
                href="https://movetrac.com/cookies"
                target="_blank"
                className={classes.consentLink}
                aria-label="Cookie use (opens in a new window)"
              >
                cookie use
                <FontAwesomeIcon
                  icon={faExternalLink}
                  style={{ marginLeft: 4, fontSize: '0.75em' }}
                />
              </Link>
              .
            </Trans>
          </div>
          <div className={classes.consentButtons}>
            <Button
              className={clsx(classes.consentButton, classes.allowButton)}
              variant="contained"
              tabIndex={cookieContext.cookieConsentOpen ? '0' : '-1'}
              onClick={() => cookieContext.toggleCookieConsent(true)}
            >
              Allow
            </Button>
            <Button
              className={clsx(classes.consentButton, classes.denyButton)}
              variant="contained"
              tabIndex={cookieContext.cookieConsentOpen ? '0' : '-1'}
              onClick={() => cookieContext.toggleCookieConsent(false)}
            >
              Deny
            </Button>
          </div>
        </div>
      </div>
    )}
  </CookieContext.Consumer>
);

CookieConsent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CookieConsent);
