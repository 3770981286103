import { theme } from '../theme';

export default {
  paddedCardInner: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      '&:last-child': {
        paddingBottom: theme.spacing(6),
      },
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.spacing(4),
      '&:last-child': {
        paddingBottom: theme.spacing(6),
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      '&:last-child': {
        paddingBottom: theme.spacing(6),
      },
    },
  },
};
