import React from 'react';

import OnboardingStatuses from '../constants/OnboardingStatuses';

const defaultOnboardingState = undefined;

// eslint-disable-next-line import/prefer-default-export
const OnboardingContext = React.createContext({
  onboardingState: defaultOnboardingState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatchOnboardingState: () => {},
});

export { OnboardingStatuses, defaultOnboardingState, OnboardingContext };
