export default {
  visuallyHidden: {
    position: 'absolute !important',
    clip: 'rect(1px, 1px, 1px, 1px)',
    padding: '0 !important',
    border: '0 !important',
    height: '1px !important',
    width: '1px !important',
    overflow: 'hidden',
  },
};
