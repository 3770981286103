export default [
  {
    url: '/img/default-avatar.svg',
    key: 'default',
    label: 'Default Avatar',
  },
  {
    url: '/img/cat-avatar.svg',
    key: 'cat',
    label: 'Cat Avatar',
    infoUrl: '/img/cat-info.svg',
    errorUrl: '/img/cat-error.svg',
    successUrl: '/img/cat-success.svg',
  },
  {
    url: '/img/dog-avatar.svg',
    key: 'dog',
    label: 'Dog Avatar',
    infoUrl: '/img/dog-info.svg',
    errorUrl: '/img/dog-error.svg',
    successUrl: '/img/dog-success.svg',
  },
  {
    url: '/img/robot-avatar.svg',
    key: 'robot',
    label: 'Robot Avatar',
    infoUrl: '/img/robot-info.svg',
    errorUrl: '/img/robot-error.svg',
    successUrl: '/img/robot-success.svg',
  },
  {
    url: '/img/monster-avatar.svg',
    key: 'monster',
    label: 'Monster Avatar',
    infoUrl: '/img/monster-info.svg',
    errorUrl: '/img/monster-error.svg',
    successUrl: '/img/monster-success.svg',
  },
  {
    url: '/img/alpaca-avatar.svg',
    key: 'alpaca',
    label: 'Alpaca Avatar',
    infoUrl: '/img/alpaca-info.svg',
    errorUrl: '/img/alpaca-error.svg',
    successUrl: '/img/alpaca-success.svg',
  },
  {
    url: '/img/dolphin-avatar.svg',
    key: 'dolphin',
    label: 'Dolphin Avatar',
    infoUrl: '/img/dolphin-info.svg',
    errorUrl: '/img/dolphin-error.svg',
    successUrl: '/img/dolphin-success.svg',
  },
  {
    url: '/img/penguin-avatar.svg',
    key: 'penguin',
    label: 'Penguin Avatar',
    infoUrl: '/img/penguin-info.svg',
    errorUrl: '/img/penguin-error.svg',
    successUrl: '/img/penguin-success.svg',
  },
  {
    url: '/img/sloth-avatar.svg',
    key: 'sloth',
    label: 'Sloth Avatar',
    infoUrl: '/img/sloth-info.svg',
    errorUrl: '/img/sloth-error.svg',
    successUrl: '/img/sloth-success.svg',
  },
  {
    url: '/img/cockatoo-avatar.svg',
    key: 'cockatoo',
    label: 'Cockatoo Avatar',
    infoUrl: '/img/cockatoo-info.svg',
    errorUrl: '/img/cockatoo-error.svg',
    successUrl: '/img/cockatoo-success.svg',
  },
  {
    url: '/img/dragon-avatar.svg',
    key: 'dragon',
    label: 'Dragon Avatar',
    infoUrl: '/img/dragon-info.svg',
    errorUrl: '/img/dragon-error.svg',
    successUrl: '/img/dragon-success.svg',
  },
  {
    url: '/img/tarsier-avatar.svg',
    key: 'tarsier',
    label: 'Tarsier Avatar',
    infoUrl: '/img/tarsier-info.svg',
    errorUrl: '/img/tarsier-error.svg',
    successUrl: '/img/tarsier-success.svg',
  },
];
