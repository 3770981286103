import { gql } from '@apollo/client';

export default gql`
  type Query {
    appState: AppState
  }

  type Mutation {
    updateAppState(input: UpdateAppStateInput): AppState
  }

  type AppState {
    startedLead: Boolean
    sidebarOpen: Boolean
    estimateActions: [estimateAction]
    snackBar: SnackBar
  }

  enum SnackBarVariant {
    info
    success
    error
  }

  type SnackBar {
    open: Boolean
    variant: SnackBarVariant
    message: String
  }

  type estimateAction {
    estimateId: String
    action: String
  }

  input estimateActionInput {
    estimateId: String
    action: String
  }

  input snackBarInput {
    open: Boolean
    variant: SnackBarVariant
    message: String
  }

  input UpdateAppStateInput {
    startedLead: Boolean
    sidebarOpen: Boolean
    estimateActions: [estimateActionInput]
    snackBar: snackBarInput
  }
`;
