export default {
  ERROR: -1,
  SUBMITTING: 0,
  NO_ACCOUNT_WITH_CUSTOMER: 'NO_ACCOUNT_WITH_CUSTOMER',
  NO_ACCOUNT_WITHOUT_CUSTOMER: 'NO_ACCOUNT_WITHOUT_CUSTOMER',
  ACCOUNT_IMPORT_NO_CUSTOMER: 'ACCOUNT_IMPORT_NO_CUSTOMER',
  ACCOUNT_NO_TYPE: 'ACCOUNT_NO_TYPE',
  ACCOUNT_NO_MOVE_HAS_ESTIMATE: 'ACCOUNT_NO_MOVE_HAS_ESTIMATE',
  // ACCOUNT_NO_MOVE_HAS_LEAD: 'ACCOUNT_NO_MOVE_HAS_LEAD',
  ACCOUNT_NO_MOVE: 'ACCOUNT_NO_MOVE',
  ACCOUNT_MOVE_NOT_INITIALIZED: 'ACCOUNT_MOVE_NOT_INITIALIZED',
  ANONYMOUS_QUOTE: false,
};
