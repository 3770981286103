import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import Footer from '../../Footer/Footer';

import styles from './LoginContainer.styles';

const useStyles = makeStyles((theme) => styles(theme));

const LoginContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.loginContainer}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
        className={classes.login}
      >
        <img
          src="./img/border-top.svg"
          alt=""
          className={clsx(classes.backgroundBorder, classes.borderTop)}
        />
        <Grid
          item
          style={{ maxWidth: '768px' }}
          classes={{
            item: classes.gridItem,
          }}
        >
          <Card className={classes.card}>{children(classes)}</Card>
          <Footer
            background="dark"
            hasSidebar={false}
            style={{ marginLeft: 0 }}
          />
        </Grid>
        <img
          src="./img/border-bottom.svg"
          alt=""
          className={clsx(classes.backgroundBorder, classes.borderBottom)}
        />
      </Grid>
    </div>
  );
};

export default LoginContainer;
