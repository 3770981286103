import { ApolloClient, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { Auth } from 'aws-amplify';

import logger from 'lib/logger.ts';
import typeDefs from './appState/types';
import resolvers from './appState/resolvers';
import cache from './cache';

const awsGraphqlFetch = async (uri, options) =>
  fetch(uri, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      i18nextLng: localStorage.getItem('i18nextLng') || 'en',
    },
  });

const httpLink = new HttpLink({
  uri: import.meta.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
  fetch: awsGraphqlFetch,
});

const onErrorLink = onError(
  // eslint-disable-next-line consistent-return
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      if (
        typeof graphQLErrors[0].path !== 'undefined' &&
        graphQLErrors[0].path[0] !== 'getAccount' &&
        graphQLErrors[0].message !== 'NO_RECORD_FOUND' &&
        graphQLErrors[0].path[0] !== 'getAccount' &&
        typeof graphQLErrors[0].path[1] !== 'undefined' &&
        graphQLErrors[0].path[1] !== 'activeMove'
      ) {
        logger.info('Retrying...');
        return forward(operation);
      } else {
        if (typeof graphQLErrors === 'object') {
          graphQLErrors.forEach((graphQLError) => {
            try {
              logger.warn(
                'graphQLError operation (path)',
                JSON.stringify(graphQLError.path),
              );
              logger.warn('graphQLError message', graphQLError.message);
              logger.warn(
                'graphQLError stacktrace',
                graphQLError.extensions.exception.stacktrace.join(' '),
              );
            } catch (error) {
              // Swallow any errors trying out logs this graphQLError.
            }
          });
        }
      }
    }
    if (networkError) {
      logger.error('Network error', networkError);
    }
  },
);

const retryLink = new RetryLink();

const client = new ApolloClient({
  link: from([onErrorLink, retryLink, httpLink]),
  cache,
  typeDefs,
  resolvers,
});

export default client;
