import smallButton from '../../../theme/styles/smallButton';
import visuallyHidden from '../../../theme/styles/visuallyHidden';
import fancySelect from '../../../theme/styles/fancySelect';
import { darkColor } from '../../../theme/theme';

import paddedCardInner from '../../../theme/styles/paddedCardInner';

const styles = (theme) => ({
  ...visuallyHidden,
  ...fancySelect,
  ...paddedCardInner,
  loginContainer: {
    display: 'flex',
  },
  login: {
    background: darkColor,
    minHeight: '100vh',
    position: 'relative',
  },
  card: {
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  smallButton: {
    ...smallButton.smallButton,
    maxHeight: 36,
    top: -6,
  },
  sentenceButton: {
    ...smallButton.smallButton,
    fontSize: '1rem',
    fontWeight: 400,
  },
  sentenceButtonBig: {
    ...smallButton.smallButton,
    fontSize: '1.1rem',
    fontWeight: 400,
    top: '-1.5px',
  },
  backgroundBorder: {
    position: 'absolute',
    left: 0,
    width: '100%',
    maxWidth: '100%',
    pointerEvents: 'none',
  },
  borderTop: {
    top: 0,
  },
  borderBottom: {
    bottom: 0,
  },
  gridItem: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2.5),
  },
  userChip: {
    fontSize: '1.25rem !important',
    padding: '6px 8px !important',
    height: 'auto !important',
    borderRadius: '20px !important',
  },
  radioGroup: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row !important',
      justifyContent: 'space-between',
    },
  },
  // temporary !important overrides in case we can't fix the style order issue
  fancySelectLabel: {
    ...fancySelect.fancySelectLabel,
    marginLeft: '0 !important',
    marginRight: '0 !important',
    [theme.breakpoints.up('md')]: {
      width: 'calc(50% - 5px)',
    },
  },
  fancySelect: {
    ...fancySelect.fancySelect,
    color: '#F7F7F9 !important',
    padding: '20px !important',
    fontSize: '1rem !important',
    transition: 'all 0.2s ease !important',
    alignItems: 'flex-start !important',
    borderRadius: '3px !important',
  },
  fancySelectFocused: {
    ...fancySelect.fancySelectFocused,
    transition: 'all 0.2s ease !important',
    color: '#e7fcf8 !important',
  },
  fancySelectChecked: {
    ...fancySelect.fancySelectChecked,
    color: '#15E8BF !important',
  },
});

export default styles;
