import React, { lazy, Suspense, useState, useEffect } from 'react';
import LogRocket from 'logrocket';
import { ApolloProvider } from '@apollo/client';
import '@reach/skip-nav/styles.css';
import { SkipNavLink } from '@reach/skip-nav';
import { Router } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import logger from 'lib/logger.ts';
import { IS_MAINTENANCE } from 'lib/utils';
import { accountIsOnboarding } from 'lib/account';
import apolloClient from 'graphql/client';
import { themeForOnboardingState } from 'theme/theme';
import {
  OnboardingStatuses,
  defaultOnboardingState,
  OnboardingContext,
} from 'contexts/OnboardingContext';
import { CookieProvider } from 'contexts/CookieContext';
import Maintenance from 'components/Maintenance';
import LoadingCard from 'components/Loading/LoadingCard';
import CookieConsent from 'components/CookieConsent';

import styles from './AppContainer.styles';
import ScrollToTop from './ScrollToTop';
import { Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => styles(theme));

const AppAuthenticate = lazy(() => import('./AppAuthenticate'));
const CsrAuthenticate = lazy(() => import('./CsrAuthenticate'));
const Onboarding = lazy(() => import('../Onboarding/Onboarding'));
const Dashboard = lazy(() => import('../Dashboard'));
const AppInit = lazy(() => import('./AppInit'));
const QuotePreview = lazy(() => import('../QuotePreview'));

const AppContainer = ({ authState, handleAuthStateChange }) => {
  const { ready } = useTranslation('translation', { useSuspense: false });
  const classes = useStyles();
  const [onboardingState, dispatchOnboardingState] = useState(
    defaultOnboardingState,
  );

  const detectAndDispatchOnboardingState = async () => {
    try {
      logger.info('detectAndDispatchOnboardingState');
      const onboarding = await accountIsOnboarding();
      logger.info(onboarding, 'onboarding');
      dispatchOnboardingState(onboarding);
    } catch (error) {
      logger.error(error);
      LogRocket.captureException(error);
      dispatchOnboardingState(OnboardingStatuses.ERROR);
    }
  };

  const onboardingProps = {
    onboardingState,
    dispatchOnboardingState,
    detectAndDispatchOnboardingState,
  };

  logger.info('AppContainer rerender');

  logger.info(onboardingState, 'onboarding?');

  const authenticated = authState === 'signedIn';

  useEffect(() => {
    logger.info('AppContainer useEffect', { authenticated, onboardingState });
    if (authenticated && onboardingState === undefined) {
      detectAndDispatchOnboardingState();
    }
    if (window.location.pathname.indexOf('/quote/') > -1) {
      dispatchOnboardingState(OnboardingStatuses.ANONYMOUS_QUOTE);
    }
  }, [authenticated, onboardingState]);

  if (!ready) {
    return (
      <MuiThemeProvider theme={themeForOnboardingState(onboardingState)}>
        <CssBaseline />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            style={{
              width: 100,
              height: 100,
              display: 'block',
              margin: '0 auto',
            }}
            thickness={1}
            color="inherit"
            aria-label="Loading..."
          />
        </Box>
      </MuiThemeProvider>
    );
  }

  return (
    <MuiThemeProvider theme={themeForOnboardingState(onboardingState)}>
      <CookieProvider>
        <CssBaseline />
        <CookieConsent />
        <Suspense fallback={<LoadingCard />}>
          {IS_MAINTENANCE && <Maintenance />}
          {!IS_MAINTENANCE && (
            <Router>
              <ScrollToTop path="/">
                <QuotePreviewContainer
                  path="quote/:encodedEstimateID/:encodedToken/*"
                  onboardingProps={onboardingProps}
                />
                <CsrAuthenticate
                  path="signup"
                  handleAuthStateChange={handleAuthStateChange}
                />
                {!authenticated && (
                  <AppAuthenticate
                    handleAuthStateChange={handleAuthStateChange}
                    default
                  />
                )}
                {authenticated && (
                  <ApolloContainer
                    default
                    // path="/"
                    onboardingState={onboardingState}
                    onboardingProps={onboardingProps}
                    classes={classes}
                  />
                )}
              </ScrollToTop>
            </Router>
          )}
        </Suspense>
      </CookieProvider>
    </MuiThemeProvider>
  );
};

const QuotePreviewContainer = (props) => (
  <OnboardingContext.Provider value={props.onboardingProps}>
    <QuotePreview {...props} />
  </OnboardingContext.Provider>
);

const ApolloContainer = ({ onboardingState, onboardingProps }) => {
  const classes = useStyles();
  return (
    <ApolloProvider client={apolloClient}>
      {onboardingState === undefined && <LoadingCard />}
      {onboardingState !== undefined && onboardingState !== false && (
        <OnboardingContext.Provider value={onboardingProps}>
          <Onboarding onboardingProps={onboardingProps} />
        </OnboardingContext.Provider>
      )}
      {onboardingState === false && (
        <>
          <SkipNavLink className={classes.skipLink} />
          <Dashboard />
          <AppInit />
        </>
      )}
    </ApolloProvider>
  );
};

export default AppContainer;
