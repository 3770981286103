import { difference } from 'lodash';
import { string } from 'yup';

import {
  faQuestion,
  faMailBulk,
  faUser,
} from '@fortawesome/pro-light-svg-icons';

import { GetAccountDocument, UpdateAccountDocument } from 'graphql/compiled';
import client from 'graphql/client';
import Avatars from 'constants/Avatars';
import OnboardingStatuses from 'constants/OnboardingStatuses';

import { dateFromDB, dbDateFromPreferredServiceDate, isSameDay } from './date';
import logger from './logger';
import { currentAuthenticatedUser } from './user';
import { getCustomersByEmail, updateCustomer } from './customer';
import { getEstimates, filterRelevantEstimates } from './estimate';
import { updateMove } from './move';

/**
 * @typedef {import("./form").AccountFormField} AccountFormField
 */

// eslint-disable-next-line import/prefer-default-export
export const getAccount = async (args) => {
  const { force = false, retry = true } = args || {};

  logger.info('getAccount');

  const response = await client.query({
    query: GetAccountDocument,
    fetchPolicy: force ? 'network-only' : 'cache-first',
    errorPolicy: 'all',
  });

  logger.info('Account response', { response });

  logger.info('Errors?', { errors: response.errors });
  if (typeof response.errors !== 'undefined') {
    const { errors } = response;
    if (errors[0].message === 'NO_RECORD_FOUND') {
      return null;
    }

    if (
      errors[0].message === 'PersistedQueryNotFound' &&
      typeof errors[0].path[0] !== 'undefined' &&
      errors[0].path[0] === 'getAccount' &&
      typeof errors[0].path[1] !== 'undefined' &&
      errors[0].path[1] === 'activeMove'
    ) {
      logger.info('No active move yet.');
      return response.data.getAccount;
    }

    logger.info('Error not NO_RECORD_FOUND');
    if (retry) {
      await new Promise((r) => setTimeout(r, 300));
      logger.info('Retrying...');
      return getAccount({ force, retry: false });
    }
    throw new Error(errors[0].message);
  }

  return response.data.getAccount;
};

// eslint-disable-next-line import/prefer-default-export
export const accountIsOnboarding = async () => {
  logger.info('executing accountIsOnboarding');
  const account = await getAccount({ force: true });
  logger.info(account, 'account');

  if (!account) {
    logger.info('No account');

    const user = await currentAuthenticatedUser();
    const customers = await getCustomersByEmail({
      email: user.attributes.email,
    });

    logger.info(customers, 'customers');

    if (customers.length) {
      logger.info(customers, 'Found customers');
      return OnboardingStatuses.NO_ACCOUNT_WITH_CUSTOMER;
    }

    logger.info(customers, 'No customers found');
    return OnboardingStatuses.NO_ACCOUNT_WITHOUT_CUSTOMER;
  }

  const {
    activeMove,
    // importCustomer,
    // customerID,
    type: accountType = false,
    leads = [],
  } = account;

  logger.info(account, 'account');

  // if (importCustomer && !customerID) {
  //   logger.info('Account should import customer but has not');
  //   return OnboardingStatuses.ACCOUNT_IMPORT_NO_CUSTOMER;
  // }

  if (!accountType) {
    logger.info('Account has no type');
    return OnboardingStatuses.ACCOUNT_NO_TYPE;
  }

  const hasActiveMove = activeMove !== null;

  logger.info(hasActiveMove, 'Active move?');

  if (hasActiveMove) {
    logger.info('Account has active move');
    if (!activeMove.initialized) {
      logger.info('Active move not initialized');
      return OnboardingStatuses.ACCOUNT_MOVE_NOT_INITIALIZED;
    }
  } else {
    logger.info('Account has no active move');
    const estimates = filterRelevantEstimates(await getEstimates());
    logger.info(estimates, 'Checking estimates');
    logger.info(leads, 'Checking leads');

    if (estimates && estimates.length) {
      logger.info('Account has estimates');
      return OnboardingStatuses.ACCOUNT_NO_MOVE_HAS_ESTIMATE;
    }
    // if (leads && leads.length) {
    //   logger.info('Account has leads');
    //   return OnboardingStatuses.ACCOUNT_NO_MOVE_HAS_LEAD;
    // }

    return OnboardingStatuses.ACCOUNT_NO_MOVE;
  }

  return false;
};

export const avatarWithKey = (key) => {
  const selectedAvatar = Avatars.find((x) => x.key === key);
  return selectedAvatar || Avatars.find((x) => x.key === 'default');
};

/**
 * @return {AccountFormField[]}
 */
export const getAccountFields = (t) => [
  {
    name: 'avatar',
    label: t(`Avatar`),
    type: 'avatar',
    validation: string(),
  },
  {
    name: 'firstName',
    label: t(`First Name`),
    icon: faUser,
    validation: string(),
    type: 'no customer',
    optional: true,
  },
  {
    name: 'emailOptIn',
    label: t(`Receive email updates`),
    icon: faMailBulk,
    type: 'boolean',
    validation: string().notRequired(),
    helperText: t(
      `We never send SPAM, but we like to share things that might interest you.`,
    ),
  },
  {
    name: 'howHeardAboutUs',
    label: t(`How did you hear about us?`),
    icon: faQuestion,
    validation: string().notRequired(),
    helperText: t(`Optional.`),
    optional: true,
    input: {
      maxLength: 255,
    },
  },
];

const updateAccount = ({ account, input }) => {
  client.writeQuery({
    query: GetAccountDocument,
    data: {
      getAccount: {
        ...account,
        input,
      },
    },
  });

  return client.mutate({
    mutation: UpdateAccountDocument,
    variables: { input },
  });
};

// eslint-disable-next-line consistent-return
export const checkForNewMwcItems = async ({
  knownCustomers,
  knownEstimates,
  estimateID,
  moveDate,
  estimatePreferredServiceDate,
}) => {
  logger.info('checkForNewMwcItems', {
    knownCustomers,
    knownEstimates,
    estimateID,
    moveDate,
    estimatePreferredServiceDate,
  });

  // We do this in case the value is null.
  // const knownCustomers = account.knownCustomers || [];

  const user = await currentAuthenticatedUser();
  const customers = await getCustomersByEmail({
    email: user.attributes.email,
    fetchPolicy: 'no-cache',
  });

  // Check for new customers.
  const customerIDs = customers.map((e) => e.id);

  logger.info(knownCustomers, 'knownCustomers');
  logger.info(customerIDs, 'customerIDs');

  const newCustomers = difference(customerIDs, knownCustomers);

  logger.info(newCustomers, 'newCustomers');

  if (newCustomers.length) {
    logger.info('updating knownCustomers');
    const account = await getAccount();
    await updateAccount({
      account,
      input: {
        knownCustomers: knownCustomers.concat(newCustomers),
      },
    });
  }

  // Search for new estimates.
  // We do this in case the value is null.
  // const knownEstimates = account.knownEstimates || [];

  const estimates = filterRelevantEstimates(await getEstimates());
  const estimateIDs = estimates.map((e) => e.id);

  logger.info(knownEstimates, 'knownEstimates');
  logger.info(estimateIDs, 'estimateIDs');

  const newEstimates = difference(estimateIDs, knownEstimates);
  const removedEstimates = difference(knownEstimates, estimateIDs);

  logger.info(newEstimates, 'newEstimates');

  if (newEstimates.length || removedEstimates.length) {
    logger.info('updating knownEstimates');
    const account = await getAccount();
    await updateAccount({
      account,
      input: {
        newEstimates,
        knownEstimates: estimateIDs,
      },
    });
  }

  // @TODO: Check for new UFL Leads?

  // Check for updates to active estimate
  // const {
  //   activeMove: {
  //     date,
  //     activeEstimateID: estimateID,
  //     activeEstimate: estimate,
  //   },
  // } = account;

  logger.info(
    'estimate preferred date',
    dateFromDB(dbDateFromPreferredServiceDate(estimatePreferredServiceDate)),
  );

  logger.info('move date', dateFromDB(moveDate));

  if (
    estimateID &&
    !isSameDay(
      dateFromDB(dbDateFromPreferredServiceDate(estimatePreferredServiceDate)),
      dateFromDB(moveDate),
    )
  ) {
    logger.info('estimate date has changed. updating move date.');

    const account = await getAccount();

    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      __typename,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      todos,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      activeEstimate,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      activeLead,
      ...moveValues
    } = account.activeMove;

    const newMoveValues = {
      ...moveValues,
      date: dbDateFromPreferredServiceDate(estimatePreferredServiceDate),
      estimateAction: null,
    };

    await updateMove(newMoveValues);
  }
};

export const updateAccountCustomers = (account, input) => {
  const { knownCustomers } = account;

  return Promise.all(
    knownCustomers.map((customerID) => updateCustomer(customerID, input)),
  );
};
